import { render, staticRenderFns } from "./AppRoot.vue?vue&type=template&id=009b8ab0&scoped=true&"
import script from "./AppRoot.vue?vue&type=script&lang=js&"
export * from "./AppRoot.vue?vue&type=script&lang=js&"
import style0 from "./AppRoot.vue?vue&type=style&index=0&lang=css&"
import style1 from "./AppRoot.vue?vue&type=style&index=1&id=009b8ab0&scoped=true&lang=css&"
import style2 from "./AppRoot.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "009b8ab0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VDialog,VIcon,VMain,VSheet,VSnackbar,VToolbar,VToolbarTitle})
