var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{class:{
    'v-app-bar--hide':
      _vm.$vuetify.breakpoint.mobile && _vm.$route.name !== 'ActivityPlanner',
  },staticStyle:{"position":"sticky","top":"0","z-index":"5"},style:({
    maxHeight: _vm.$vuetify.breakpoint.mobile ? '56px' : '64px',
  }),attrs:{"color":"primary","dark":""}},[_c('img',{staticClass:"me-4 py-2",staticStyle:{"height":"100%"},attrs:{"alt":"Scouts Aotearoa logo","src":require("@/assets/images/logo.svg"),"draggable":"false"}}),_c('v-app-bar-title',[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.name === "xs" ? "AMS" : "Activity Management System")+" ")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[(_vm.photoURL)?_c('img',{staticStyle:{"width":"36px","border-radius":"50%"},attrs:{"src":_vm.photoURL,"alt":"User picture"}}):_vm._e(),(!_vm.photoURL)?_c('avatar',{attrs:{"username":_vm.displayName,"size":36,"backgroundColor":"var(--v-primary-darken1)","color":"#ffffff"}}):_vm._e()],1)]}}])},[_c('v-list',{staticStyle:{"max-width":"15rem"},attrs:{"dense":"","nav":""}},[_c('v-list-item',[_c('v-list-item-title',{staticClass:"font-weight-regular text-body-2"},[_vm._v(" Kia ora, "+_vm._s(_vm.displayName)+"! ")])],1),_c('v-subheader',[_vm._v("Menu")]),_c('v-list-item',{attrs:{"to":{ name: 'About' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.informationIcon))])],1),_c('v-list-item-title',{staticClass:"font-weight-regular text-body-2"},[_vm._v(" About ")])],1),(_vm.showInstallItem)?_c('v-list-item',{on:{"click":function($event){return _vm.$appPrompt.prompt()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.downloadIcon))])],1),_c('v-list-item-title',{staticClass:"font-weight-regular text-body-2"},[_vm._v(" Install App ")])],1):_vm._e(),_c('v-subheader',[_vm._v("Account")]),_c('v-list-item',{attrs:{"to":{ name: 'AccountProfile' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.accountIcon))])],1),_c('v-list-item-title',{staticClass:"font-weight-regular text-body-2"},[_vm._v(" My Account ")])],1),_c('v-list-item',{on:{"click":_vm.signOut}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.logoutIcon))])],1),_c('v-list-item-title',{staticClass:"font-weight-regular text-body-2"},[_vm._v(" Sign Out ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }