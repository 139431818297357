var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-list-item',{staticClass:"flex-wrap my-n3 px-0 px-sm-4",class:{
      'mb-n4': _vm.$vuetify.breakpoint.name === 'xs',
    }},[_c('v-list-item-avatar',[(_vm.photoURL)?_c('img',{staticStyle:{"width":"40px","border-radius":"50%"},attrs:{"src":_vm.photoURL,"alt":"User picture"}}):_c('avatar',{attrs:{"username":_vm.displayName ? _vm.displayName : _vm.email,"size":40,"backgroundColor":"var(--v-primary-darken1)","color":"#ffffff"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s((_vm.uid ? (_vm.displayName ? _vm.displayName : _vm.email) : "No AMS Account") + (_vm.email === _vm.$currentUser.email ? " (You)" : "")))]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.email.replace(/\&period;/g, '.'))}})],1),_c('v-list-item-action',{staticClass:"d-flex flex-row align-self-center justify-start",class:{
        'mb-n16': _vm.$vuetify.breakpoint.name === 'xs',
        'mt-n8': _vm.$vuetify.breakpoint.name === 'xs',
        'ml-14': _vm.$vuetify.breakpoint.name === 'xs',
      },style:({
        'flex-basis': _vm.$vuetify.breakpoint.name === 'xs' ? '100%' : null,
      })},[_c('v-select',{staticStyle:{"max-width":"8.75rem"},attrs:{"items":_vm.activityRoles,"label":"Role","solo":"","dense":"","single-line":"","persistent-hint":"","menu-props":{ bottom: true, offsetY: true },"loading":_vm.roleLoading,"hide-details":"auto","disabled":_vm.disabled,"flat":_vm.disabled,"append-icon":_vm.disabled ? null : undefined},on:{"change":function($event){return _vm.updatePerson(false)}},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),(!_vm.disabled)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":"","disabled":_vm.showRemove},on:{"click":function($event){_vm.showRemove = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.deleteIcon))])],1)]}}],null,false,1797875884)},[_c('span',[_vm._v("Remove user")])]):_vm._e()],1)],1),(_vm.showRemove)?_c('v-sheet',{staticClass:"pa-4 mb-2",class:{
      'mt-2': _vm.$vuetify.breakpoint.name !== 'xs',
    },attrs:{"color":"error","dark":"","elevation":"2","rounded":""}},[_c('p',[_vm._v("Are you sure you want to remove this person?")]),_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"gap":"0.5rem 1rem","width":"100%"}},[_c('v-btn',{attrs:{"outlined":"","loading":_vm.removeLoading,"disabled":_vm.removeLoading},on:{"click":function($event){return _vm.updatePerson(true)}}},[_vm._v(" Delete ")]),_c('v-btn',{attrs:{"plain":""},on:{"click":function($event){_vm.showRemove = false}}},[_vm._v("Cancel")])],1)]):_vm._e(),_c('Alert',{staticClass:"mb-2",class:{
      'mt-2': _vm.$vuetify.breakpoint.name !== 'xs',
    },attrs:{"dismissable":"","type":"error","message":_vm.error}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }