var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"autosave-text",staticStyle:{"position":"relative"}},[_c('transition',{attrs:{"name":"fade-transition"}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"custom","height":"48px","width":"100%"}}):_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(_vm.type == 'combobox'
            ? _vm.vCombobox
            : _vm.type == 'textarea'
            ? _vm.vTextarea
            : _vm.type == 'autocomplete'
            ? _vm.VAutocomplete
            : _vm.vTextField,{ref:"input",tag:"component",class:{ 'v-input--pointer': _vm.type === 'date' || _vm.type === 'time' },staticStyle:{"width":"100%"},attrs:{"label":_vm.label,"type":_vm.type == 'number' ? 'number' : 'text',"items":_vm.comboboxItems,"min":_vm.type == 'number' ? '1' : null,"step":_vm.type == 'number' ? '1' : null,"onkeypress":_vm.type == 'number'
            ? 'return event.charCode >= 48 && event.charCode <= 57'
            : null,"disabled":_vm.disabled,"autocomplete":_vm.autocomplete,"rows":_vm.type == 'textarea' ? 3 : null,"rules":_vm.rules,"required":_vm.required,"prepend-icon":_vm.icon,"hide-details":"auto","readonly":_vm.type === 'date' || _vm.type === 'time',"multiple":_vm.chips,"chips":_vm.chips},on:{"blur":_vm.save,"click":function($event){_vm.showPickerDialog = _vm.type === 'date' || _vm.type === 'time'},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }_vm.showPickerDialog = _vm.type === 'date' || _vm.type === 'time'}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [((_vm.showButton || _vm.showSuccess) && _vm.valid)?_c('div',{staticClass:"mt-n1 mb-n2 ms-n2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({},on),[(_vm.showButton || _vm.showSuccess)?_c('v-btn',{attrs:{"icon":"","loading":_vm.currentlySaving},on:{"click":function($event){$event.stopPropagation();return _vm.save.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":_vm.showButton ? 'error' : 'success'}},[_vm._v(" "+_vm._s(_vm.showButton ? _vm.contentSaveIcon : _vm.checkCircleIcon)+" ")])],1):_vm._e()],1)]}}],null,false,1442227941)},[(_vm.showButton)?_c('span',[_vm._v(" "+_vm._s(_vm.currentlySaving ? "Saving" : "Save")+" ")]):_vm._e(),(_vm.showSuccess)?_c('span',[_vm._v("Saved")]):_vm._e()])],1):_vm._e()]},proxy:true}]),model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}}),_c('Alert',{staticClass:"mt-2",attrs:{"dismissable":"","type":"error","message":_vm.error && _vm.error.message ? _vm.error.message : null,"link":_vm.error && _vm.error.link
            ? { text: _vm.error.linkText, link: _vm.error.link }
            : null}}),(_vm.type === 'date' || _vm.type === 'time')?_c('PickerDialog',{attrs:{"show":_vm.showPickerDialog,"value":_vm.currentValue,"type":_vm.type},on:{"close":function () {
            _vm.showPickerDialog = false;
            _vm.$refs.input.blur();
          },"save":function (value) {
            _vm.currentValue = value;
            _vm.showPickerDialog = false;
            _vm.$refs.input.blur();
            _vm.save();
          }}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }